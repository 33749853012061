import styled from 'styled-components';

import Loading from '@/components/atoms/Loading';
import { Layout } from '@/components/templates/Layout';

const Gymx = () => {
  return (
    <Layout>
      <Container>
        <Loading size={32} />
      </Container>
    </Layout>
  );
};

export default Gymx;

const Container = styled('div')`
  display: flex;
  justify-content: center;
  padding: 0 12px;
  ${({ theme }) => theme.breakpoints.lg`
    padding: 0 60px;
  `};
  height: 100%;
  align-items: center;
`;
